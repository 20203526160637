<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <BrandServiceForm
      v-if="!isLoading"
      :brandService="brandService"
      v-on:addOrUpdateBrandService="addOrUpdateBrandService()"
      :submitName="$t('edit')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import { STATUS } from "@/utils/constants";
import BrandServiceForm from "@/components/brandServices/BrandServiceForm.vue";
import BrandService from "@/models/brandServices/BrandService";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    BrandServiceForm,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
      brandService: new BrandService(),
    };
  },
  props: ["brandServiceToken"],
  methods: {
    async addOrUpdateBrandService() {
      this.isLoading = true;
      try {
        const response = await this.brandService.addOrUpdateBrandService(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.brandService.setInitialValue();
          this.showMsg(response.data.msg, true);
          this.$router.push({ name: "BrandServices" }).catch(() => {});
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getBrandServiceDetails() {
      this.isLoading = true;
      this.brandService.brandServiceToken = this.brandServiceToken;
      try {
        let response = await this.brandService.getBrandServiceDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.brandService.fillData(response.data.brandServices);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
        } else {
          this.showMsg(response.data.msg);
          this.$router.push({ name: "BrandServices" }).catch(() => {});
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.$router.push({ name: "BrandServices" }).catch(() => {});
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getBrandServiceDetails();
  },
};
</script>
